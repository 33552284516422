<template>
  <div id="geographical-indications">
    <div class="content">
      <div class="box" v-if="show">
        <img src="@/assets/images/ycd_1.png" alt="" />
        <div class="introduction">
          <h3>
            <img src="@/assets/images/ycd_logo1.png" alt="" />地理标志保护产品
          </h3>
          <p>
            2002年，公司正山小种红茶获得国家第23号原产
            地标记认定，正山堂成为首个获得中华人民共和国
            地理标志保护产品之武夷山正山小种红茶授权的红 茶企业。
          </p>
        </div>
        <img src="@/assets/images/ycd_2.png" alt="" />
        <div class="introduction">
          <h3>
            <img src="@/assets/images/ycd_logo2.png" alt="" /><span
              >权威制度——三大标准制度企业</span
            >
          </h3>
          <ul>
            <li>①起草、制度正山小种红茶国家标准的企业单位</li>
            <li>②主导、制度金骏眉红茶国家标准的企业单位</li>
            <li>③牵头制度骏眉红茶团体标准的企业单位</li>
          </ul>
        </div>
      </div>
      <none v-else />
    </div>
  </div>
</template>

<script>
//A45C9266C090436FA9319ADBF480E801
import none from "@/components/none.vue";
export default {
  name: "geographical-indications",
  components: {
    none,
  },
  data() {
    return {
      show: false,
    };
  },

  created() {
    if (sessionStorage.getItem("id") === "A45C9266C090436FA9319ADBF480E801") {
      this.show = true;
    }
  },

  methods: {},
};
</script>

<style lang="scss" scoped>
#geographical-indications {
  padding: 15px 12px;
  background-color: #f5f5f5;
  .content {
    border-radius: 8px;
    background-color: #fff;
    padding: 15px 12px;
    display: flex;
    flex-direction: column;
    .box {
      > img {
        width: 100%;
      }
      .introduction {
        margin: 16px 0 20px 0;
        h3 {
          display: flex;
          font-size: 16px;
          font-weight: bold;
          color: #01334c;
          height: 18px;
          align-items: center;
          margin-bottom: 10px;
          img {
            width: 18px;
            margin-right: 6px;
          }
        }
        p {
          font-size: 14px;
          color: #333333;
          line-height: 20px;
        }
        ul {
          font-size: 14px;
          color: #666666;
          line-height: 20px;
        }
      }
      .introduction:last-child {
        margin-bottom: 0;
      }
    }
  }
}
</style>